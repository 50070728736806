<template>
  <b-modal
    id="modal-market-place"
    hide-footer
    header-class="modal-header-marketplace py-2"
  >
    <template #modal-title> Connect New Marketplace </template>
    <div class="market-place-container">
      <div v-for="(list, n) of marketplace_channel" :key="n">
        <!-- :src="
            require(`@/assets/icons/marketplace/${list.name.toLowerCase()}.png`)
          " -->
        <img
          @click="$emit('handleImageClick', list)"
          class="market-place-image-logo"
          :src="list.image_url"
          alt="image market place logo"
        />
      </div>
    </div>
    <!-- <template #modal-footer class="py-0">
      <div class="d-flex justify-content-between w-100">
        <b-button
          class="btn-cancel btn-width"
          @click="$bvModal.hide('modal-market-place')"
          >CANCEL
        </b-button>
      </div>
    </template> -->
  </b-modal>
</template>

<script>
export default {
  props: ["marketplace_channel"],
  methods: {},
};
</script>

<style></style>
